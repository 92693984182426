export const COURSE_ONLINE_DETAIL = "COURSE_ONLINE_DETAIL";
export const COURSE_ONLINE_GET = "COURSE_ONLINE_GET";
export const COURSE_ONLINE_CREATE = "COURSE_ONLINE_CREATE";
export const COURSE_ONLINE_UPDATE_IN_LIST = "UPDATE_COURSE_ONLINE_IN_LIST";
export const COURSE_ONLINE_LOADING = "COURSE_ONLINE_LOADING";
export const COURSE_ONLINE_LOADED = "COURSE_ONLINE_LOADED";
export const COURSE_ONLINE_DELETE = "COURSE_ONLINE_DELETE";
// FETCH ALL COURSE
export const FETCH_COURSE_ONLINE_REQUESTING = "FETCH_COURSE_ONLINE_REQUESTING";
export const FETCH_MORE_COURSE_ONLINE_SUCCESS =
  "FETCH_MORE_COURSE_ONLINE_SUCCESS";
export const FETCH_COURSE_ONLINE_SUCCESS = "FETCH_COURSE_ONLINE_SUCCESS";
export const FETCH_COURSE_ONLINE_FAILED = "FETCH_COURSE_ONLINE_FAILED";
